<template>
  <div class="workflow-process">
     <div class="process-step" v-for="(step, index) in processSteps" :key="index">
       <div class="step-number">{{ step.number }}</div>
       <div class="step-title">{{ step.title }}</div>
       <div class="step-description">{{ step.description }}</div>
     </div>
  </div>
 </template>
 
 <script>
 export default {
  data() {
     return {
       processSteps: [
         { number: 1, title: 'Step 1', description: 'This is the first step in the workflow process.' },
         { number: 2, title: 'Step 2', description: 'This is the second step in the workflow process.' },
         { number: 3, title: 'Step 3', description: 'This is the third step in the workflow process.' },
         // Add more steps as needed
       ],
     };
  },
 };
 </script>
 
 <style scoped>
 .workflow-process {
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 
 .process-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
 }
 
 .step-number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
 }
 
 .step-title {
  font-size: 18px;
  font-weight: bold;
 }
 </style>